<template>
  <static-fullscreen-card>
    <template v-slot:header></template>
    <template v-slot:actions>
      <v-btn color="primary" @click="getData()"><btn-title icon="fas fa-sync-alt">Обновить</btn-title> </v-btn>
    </template>
    <portal to="v-main">
      <edit-dialog v-model="showEditDialog" :id="idEdit"></edit-dialog>
    </portal>
    <a-table-f-api v-if="false" ref="table" :api="url" :model="model" :useQuery="false" :defaults="defaults" @click="onClickRow($event)" />

    <a-table-f-data2 ref="table" :dataTable="dataTable" :model="model" :useQuery="false" :defaults="defaults" @click="onClickRow($event)" @updateData="getData()" />
  </static-fullscreen-card>
</template>

<script>
export default {
  components: {
    editDialog: () => import("./dialogs/infoViewDialog"),
  },
  data() {
    return {
      idEdit: 0,
      showEditDialog: false,
      model: this.$store.getters["config/get"].models.info.list,
      dataTable: [],
      model1: [
        {
          name: "id",
          title: "#",
          type: "id",
          sortable: true,
          width: 50,
        },
        {
          name: "name",
          title: "Наименование",
          type: "string",
          sortable: true,
          width: 450,
        },
      ],
      url: "/motylek/information",
      defaults: {
        sort: { key: "id", order: "DESC" },
      },
    };
  },
  computed: {},
  created() {
    this.$root.title = "Информация";
    this.getData();
  },

  methods: {
    async getData() {
      let resp = await this.$axios.post("/report-sql", {
        q: `
select * , JSON_VALUE(data,'$.group') 'group' from information  where FIND_IN_SET('${this.$root.profile.role}',JSON_VALUE(data,'$.role')) > 0 or FIND_IN_SET('-1',JSON_VALUE(data,'$.role')) > 0
`,
      });
      if (resp.data.status == "ok") {
        this.dataTable = resp.data.data;
      }
    },

    onClickRow(d) {
      this.showEditDialogFun(d.row.id);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
  },
};
</script>
